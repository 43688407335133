import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";

function App() {
  const Features = React.lazy(() => import("./pages/Features"));
  const Tips = React.lazy(() => import("./pages/Tips"));
  const ClassSaathiBlogsScreen = React.lazy(() => import("./pages/ClassSaathiBlogs/ClassSaathiBlogs"));
  const BlogDetails = React.lazy(() => import("./pages/BlogDetails"));
  return (
    <div className="App">
      <div className="wrapper">
        <Router>
          <Switch>
            <Suspense fallback={<div></div>}>
              <Route exact path="/features" component={Features} />
              <Route exact path="/tips" component={Tips} />
              <Route exact path="/class-saathi-blogs" component={ClassSaathiBlogsScreen} />
              <Route path="/blog-details" component={BlogDetails} />
            </Suspense>
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
